import React, { useEffect, useRef, useState, useCallback } from "react";
import './styles.css';
import { useParams } from "react-router-dom";
import { AppSvg } from "../../../assets/svg";
import { useIsLoggedIn, useUser } from "../../../redux/useStore";
import { staticSuggestions, Strings } from "../../../utils/constants";
import { ChatHeader } from "../../../components";
import { v4 as uuidv4 } from 'uuid';
import { useAxios } from "../../../hooks/useAxios";
import errorHandler from "../../../utils/common/errHandler";
import { MessageComponent } from "../../../components/message";
import { ApiCollection, ApiHeadNormal } from "../../../config/envConfig";
import { startEventStream } from "../../../utils/common/eventSource";
import ReportBoxModal from "../../../components/reportBox";
import ConversionLimitModal from "../../../components/ConvLimitReached";
import SubscriptionModal from "../../../components/PremiumSubscriptionModal";
import LoadingModal from "../../../components/loading-modal";
import FileSizeWarningModal from "../../../components/FileSizeWarningModal";
// interface QueueMessage {
//   id: any;
//   text: any;
//   sources: any;
// }

// Add this interface near the top of the file
// interface Attachment {
//   uri: string;
//   type: string;
//   fileName: string;
// }

function ChatScreen() {

  const { id } = useParams()
  const isLoggedIn = useIsLoggedIn()
  const user = useUser()
  const axios = useAxios()
  const isProSelected = user?.isProSelected


 

  // const pathName = window.location.pathname;


  const [input, setInput] = React.useState<string>('')
  const [messages, setMessages] = React.useState<any>([])
  const [chatsLoading, setChatsLoading] = React.useState<boolean>(false)
  const [isGenerating, setIsGenerating] = React.useState<boolean>(false)
  const [showModal, setShowModal] = useState(false);
  const [showAllSources, setShowAllSources] = useState(false);
  const [limitExceedModal, setLimitExceedModal] = useState(false);
  const [chatId, setChatId] = useState<any>(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [suggestions, setSuggestions] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [showFileSizeWarning, setShowFileSizeWarning] = useState(false);


  const eventSourceRef = useRef<any | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const attachmentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (id) {
      getChats()
    } else {
      setChatId(null)
      setMessages([])
      setChatsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  const closeFeedbackModal = () => {
    setShowModal(false);
  };

  const openFeedbackModal = () => {
    setShowModal(true);
  };


  const handlePremium = () => {
    setShowSubscriptionModal(true);
    setLimitExceedModal(false)

  }



  const getChats = async () => {
    setChatsLoading(true);
    await axios
      .get(`/history/${id}/`)
      .then((response) => {
        if (response.data && response.data.messages) {
          // console.log("Chat History Fetched", response.data.messages);
          console.log("response.data.messages", response.data.messages)
          const formattedMessages = response.data.messages.map(
            (msg: any) => ({
              id: msg.id,
              text: msg.content,
              isBot: msg.role === "assistant",
              created: msg.created,
              attachments: (msg.type && msg.uri) ? [{ uri: `${ApiHeadNormal}${msg.uri}`, type: msg.type, fileName: msg.name, token: user?.token }] : []
            })
          );
          setChatId(id);
          setChatsLoading(false);
          setMessages(formattedMessages);
          // console.warn(JSON.stringify(formattedMessages));
        } else {
          setChatsLoading(false);
        }
      })
      .catch((error) => {
        setChatsLoading(false);
        errorHandler('Get Chats', error);
      });
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`suggestions/?limit=${3}`);
        if (response.data && response.data.length > 0) {
          setSuggestions(response.data);
        } else {
          setSuggestions(staticSuggestions);
        }
      } catch (error) {
        setSuggestions(staticSuggestions);
        console.error(`Error fetching Suggestions for the limit set to ${3}:`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  const askBot = async (query: string) => {
    let conversation_id = chatId;


    setIsGenerating(true);
    if (conversation_id === null) {
      try {
        const response = await axios.get(ApiCollection.chats.normalChat);
        conversation_id = response.data.conversation_id;
        setChatId(conversation_id);
      } catch (error) {
        errorHandler("New Chat", error);
        const msg = {
          id: uuidv4(),
          text: `${Strings.errors.networkError}`,
          isBot: true,
          isError: true,
          created: new Date(),
        };
        setMessages([...messages, msg]);
        onStopStreaming();
        return;
      }
    }

    let token = user?.token;
    let data;
    let sourceUrl;
    const is_ios = false;

    if (attachments.length > 0) {
      try {
        const form = new FormData();
        form.append("query", query);
        form.append("conversation_id", conversation_id || '');

        for (const attachment of attachments) {
          if (attachment.type.includes("image")) {
            form.append("image", attachment);
          } else if (attachment.type.includes("audio")) {
            form.append("audio", attachment);
          }
        }
        sourceUrl = ApiCollection.chats.chatVision;
        data = form;
      } catch (error) {
        console.error("Error processing attachments:", error);
        const msg = {
          id: uuidv4(),
          text: "There was an error processing your attachment. Please try again or use a different file.",
          isBot: true,
          isError: true,
          created: new Date(),
        };
        setMessages([...messages, msg]);
        onStopStreaming();
        return;
      }
    } else if (user?.isPro && isProSelected) {
      sourceUrl = ApiCollection.chats.googleSearchEngine;
      data = JSON.stringify({ query, conversation_id, is_ios });
    } else {
      sourceUrl = ApiCollection.chats.normalChat;
      data = JSON.stringify({ query, conversation_id, is_ios });
    }

    try {
      const eventSource = startEventStream(
        attachments.length > 0 ? "formdata" : "json",
        sourceUrl,
        token,
        data,
        onMessage,
        onError
      );

      if (eventSource) {
        eventSourceRef.current = eventSource;
      }
    } catch (error) {
      console.error("Error starting event stream:", error);
      const msg = {
        id: uuidv4(),
        text: "There was an error connecting to the chat service. Please try again later.",
        isBot: true,
        isError: true,
        created: new Date(),
      };
      setMessages([...messages, msg]);
      onStopStreaming();
    }
  }

  const onMessage = (e: MessageEvent, eventSource: EventSource) => {

    // Check if the data is "[DONE]" before parsing
    if (e.data === "[DONE]") {
      console.log(e.data, "Done");
      onStopStreaming();
      setIsGenerating(false);
      return;
    }


    let parsedData;
    try {
      parsedData = JSON.parse(e.data);
    } catch (error) {
      console.error("Failed to parse message data:", e.data);
      return;
    }

    const userSubscriptionActive = user?.isPro;
    const userSubscriptionIsPro = user?.isProSelected;

    // Checking if both conditions are true
    if (userSubscriptionActive && userSubscriptionIsPro) {
      handlePremiumVersionMessage(e, eventSource);
    }

    if (parsedData.status === "End") {
      onStopStreaming();
      setIsGenerating(false);
    }

    handleNormalChatMessage(e);
  };



  const handlePremiumVersionMessage = (e: MessageEvent, eventSource: EventSource) => {
    if (!e.data) {
      console.warn("Received empty event data");
      return;
    }

    let payload;
    try {
      payload = JSON.parse(e.data);
    } catch (error) {
      console.error("Failed to parse event data:", error);
      return;
    }

    const { status, step, response, sources, id } = payload || {};


    if (!id) {
      console.warn("Missing message ID in payload");
      return;
    }



    if (
      status !== "Processing" &&
      status !== "step" &&
      status !== "End" &&
      step === undefined &&
      !sources
    ) {
      const googleBotMessage = response || '';
      const googleBotMessageId = id;

      setMessages((prevMessages: any) => {
        if (!Array.isArray(prevMessages)) return [];

        const newMessages = [...prevMessages];
        const existingIndex = newMessages.findIndex(msg => msg?.id === googleBotMessageId);

        if (existingIndex === -1) {
          newMessages.push({
            id: googleBotMessageId,
            text: googleBotMessage,
            isBot: true,
          });
        } else {

          newMessages[existingIndex].text =
            (newMessages[existingIndex].text || '') +
            (googleBotMessage || '');
        }

        return newMessages;
      });

    } else if (sources && !response) {
      const parsedSources = (sources || '')
        .split("\n")
        .map((line: string) => {
          try {
            const match = line.match(/\[(.+?)\]\((.+?)\)/);
            return match ? match[2] : null;
          } catch {
            return null;
          }
        })
        .filter((source: any): source is string => Boolean(source));

      const googleBotMessage = response || '';
      const googleBotMessageId = id;

      setMessages((prevMessages: any) => {
        if (!Array.isArray(prevMessages)) return [];

        const newMessages = [...prevMessages];
        newMessages.push({
          id: googleBotMessageId,
          text: googleBotMessage,
          isBot: true,
          sources: parsedSources
        });
        return newMessages;
      });

    } else if (step === true && !response) {
      // setSearchingWeb(true);
    } else if (status === "End") {
      if (typeof onStopStreaming === 'function') {
        onStopStreaming();
      }

      if (typeof setIsGenerating === 'function') {
        setIsGenerating(false);
      }

      console.log("Google Event Stream ended");
    }
  };



  const handleNormalChatMessage = (e: any) => {
    if (e.data !== "[DONE]") {
      const payload = JSON.parse(e.data);

      // Check if choices array exists and has at least one element
      if (payload?.choices && payload.choices.length > 0) {
        const botMessage = payload.choices[0].text;
        const botMessageId = payload.id;
        const sourcesPayload = payload.choices?.[0]?.sources || [];

        setMessages((prevMessages: any) => {
          const newMessages = [...prevMessages];
          const existingIndex = newMessages.findIndex(msg => msg.id === botMessageId);
          if (existingIndex === -1) {
            newMessages.push({
              id: botMessageId,
              text: botMessage,
              isBot: true,
              sources: sourcesPayload,
            });
          } else {
            newMessages[existingIndex].text += botMessage;
            if (sourcesPayload?.length > 0) {
              newMessages[existingIndex].sources = sourcesPayload;
            }
          }
          return newMessages;
        });
      } else {
        console.warn("No choices available in the response", payload);
      }
    }
  };




  const onStopStreaming = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    setIsGenerating(false);
  };

  const onError = (error: any) => {
    console.log("Chat Error", error?.responseCode)

    onChatError(error);
    // Stop generating.....
    setIsGenerating(false);
    onStopStreaming();
  };

  const onChatError = (error: any) => {
    // const code = JSON.parse(error.message).status;
    const myError = JSON.parse(error?.data);
    const code = myError?.status || 500;
    let text = myError.detail || null
    if (code === 429) {
      setLimitExceedModal(true);
    }

    if (text === null) {

      switch (code) {
        case 400:
          text = Strings.errors.networkError;
          break;

        case 401:
          text = Strings.errors.sessionExpired;
          break;

        case 404:
          text = Strings.errors.notFound;
          break;

        case 409:
          text = Strings.errors.alreadyExits;
          break;

        case 429:
          text = isLoggedIn ? user?.isPro ? Strings.errors.proLimitReached : Strings.errors.userLimitReached : Strings.errors.guestLimitReached;
          break;

        case 500:
          text = Strings.errors.serverEror;
          break;

        case 503:
          text = Strings.errors.serverEror;
          break;

        default:
          text = Strings.errors.unknownError;
          break;
      }
    }



    if (text) {
      const msg = {
        id: uuidv4(),
        text: text,
        isBot: true,
        isError: true,
        created: new Date(),
      };
      setMessages([...messages, msg]);
    }
  }


  const onSendMessage = async () => {
    if (input.trim() === '' && attachments.length === 0) {
      return;
    }

    const newMessage = {
      id: uuidv4(),
      text: input,
      isBot: false,
      created: new Date(),
      attachments: attachments.length > 0 ? attachments : [],
    };

    setMessages([...messages, newMessage]);
    setInput('');

    askBot(input);

    setPreviewImage(null);
    setAttachments([]);
    setShowAttachments(false);
  }




  const onSuggestionPress = (suggestion: any) => {
    if (!suggestion.value) {
      return;
    }

    const newMessage = {
      id: uuidv4(),
      text: suggestion.value,
      isBot: false,
      created: new Date(),
    };

    setMessages([...messages, newMessage])
    askBot(suggestion.value)
  }


  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    if (e.target.value === '') {
      resetTextareaHeight();
    } else {
      adjustTextareaHeight();
    }
  };


  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '28px';
      inputRef.current.style.height = `${Math.max(inputRef.current.scrollHeight, 28)}px`;
    }
  };

  const resetTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '28px';
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSendMessage();
    } else if (e.key === 'Backspace' && input.length === 1) {
      setTimeout(resetTextareaHeight, 0);
    }
  };




  const extractDomain = (url: any) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname.replace("www.", "");
    } catch (error) {
      console.error("Invalid URL:", error);
      return null;
    }
  };

  const getFaviconUrl = (domain: any) => {
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
  };


  const toggleAttachments = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowAttachments(prevState => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (attachmentRef.current && !attachmentRef.current.contains(event.target as Node)) {
        setShowAttachments(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFileUpload = useCallback((type: 'image' | 'audio') => {
    console.log("handleFileUpload called with type:", type);
    if (fileInputRef.current) {
      fileInputRef.current.accept = type === 'image'
        ? 'image/jpeg,image/png,image/gif,image/jpg'
        : 'audio/wav,audio/mpeg';
      fileInputRef.current.value = '';
      console.log("Attempting to click file input");
      fileInputRef.current.click();
    } else {
      console.error("File input reference is null");
    }
  }, []);


  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("onFileSelected called");
    const file = event.target.files?.[0];
    if (file) {
      console.log('Uploaded file:', file);



      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        setShowFileSizeWarning(true);
        return;
      }

      console.log("File selected:", file);
      setAttachments([file]);

      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const result = e.target?.result as string;
          console.log("FileReader result:", result);
          setPreviewImage(result);
        };
        reader.onerror = (error) => {
          console.error("FileReader error:", error);
        };
        reader.readAsDataURL(file);
      } else {
        console.log("Not an image file, setting preview to null");
        setPreviewImage(null);
      }

      setShowAttachments(false);
    } else {
      console.log("No file selected");
      setPreviewImage(null);
      setAttachments([]);
    }
  };



  const isDisabled = () => {
    if (attachments.length > 0) {
      const attachment = attachments[0];
      if (attachment.type.startsWith('image/')) {
        return input.trim() === "";
      } else if (attachment.type.startsWith('audio/')) {
        return false;
      }
    }

    return input.trim() === "";
  };


  //for copy pasting the image
  const handlePaste = useCallback((event: ClipboardEvent) => {
    const items = event.clipboardData?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1 && items[i].type !== 'image/svg+xml') {
          event.preventDefault();
          const blob = items[i].getAsFile();
          if (blob) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const result = e.target?.result as string;
              setPreviewImage(result);
              setAttachments([new File([blob], "pasted-image.png", { type: blob.type })]);
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);




  return (
    <>
      <div className="chat-screen">
        <ChatHeader />


        {!chatsLoading ? (
          messages.length > 0 ? (
            <div className="chat-content filled">
              {messages.map((message: any, index: any) => (
                <MessageComponent
                  key={message.id}
                  message={message}
                  isLastMessage={index === messages.length - 1}
                  isGenerating={false}
                  isError={message.isError}
                  showIcon={true}
                  toggleFlagActionsheet={openFeedbackModal}
                />
              ))}
              {isGenerating && messages.length > -1 && (
                <MessageComponent
                  message={{
                    isBot: true,
                    text: "",
                  }}
                  isLastMessage={true}
                  isGenerating={isGenerating}
                  isError={false}
                  showIcon={messages[messages.length - 1].isBot === false}
                  toggleFlagActionsheet={() => setShowModal(true)}
                />
              )}
              {messages.length > 0 && messages[messages.length - 1].sources && messages[messages.length - 1].sources.length > 0 && (
                <div className="sources-main">
                  <h3 className="source-title">Sources:</h3>
                  <div className="sources-box">
                    {messages[messages.length - 1].sources
                      .slice(0, showAllSources ? messages[messages.length - 1].sources.length : 3)
                      .map((source: any, idx: any) => {
                        const domain = extractDomain(source);
                        return (
                          <a
                            href={source}
                            className="source-btn"
                            key={idx}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={getFaviconUrl(domain)}
                              alt={`${domain} favicon`}
                              className="favicon"
                            />
                            <span className="source-text">{domain}</span>
                          </a>
                        );
                      })}
                    {!showAllSources && messages[messages.length - 1].sources.length > 3 && (
                      <button
                        className="source-btn"
                        onClick={() => setShowAllSources(true)}
                      >
                        <span className="source-text">+{messages[messages.length - 1].sources.length - 3} more</span>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="chat-content empty">
              <h1>Namaste {user?.firstName}</h1>
              <h2>How can I help you today?</h2>
              <div className="suggestion-holder">
                {loading ? (
                  <LoadingModal modalVisible={true} />
                ) : (
                  suggestions?.map((suggestion: any, index: any) => (
                    <div key={index} className="suggestion" onClick={() => onSuggestionPress(suggestion)}>
                      <img src={suggestion.icon} alt="suggestion" style={{ width: 25, height: 25 }} />
                      <span>{suggestion.value}</span>
                    </div>
                  ))
                )}
              </div>

            </div>
          )
        ) : (
          <div className="chat-content empty">
            <img src={AppSvg.loading} alt="loading" style={{ width: '30px', height: '30px' }} />
            <h3 style={{ marginLeft: 15, fontWeight: 'normal' }}>Loading Chats...</h3>
          </div>
        )}

        <ReportBoxModal isOpen={showModal} onClose={closeFeedbackModal} />

        <div className="input-box">
          <div className="input-wrapper">
            <div className={`chat-input ${attachments.length > 0
                ? attachments[0].type.startsWith('audio')
                  ? 'with-audio-preview'
                  : 'with-attachment'
                : ''
              }`}>
              {attachments.length > 0 && (
                attachments[0].type.startsWith('image/') ? (
                  <div className="image-preview">
                    {previewImage && <img src={previewImage} alt="Preview" />}
                    <button onClick={() => {
                      setAttachments([]);
                      setPreviewImage(null);
                    }}>×</button>
                  </div>
                ) : (
                  <div className="audio-preview">
                    <div className="audio-waveform">
                      {[...Array(20)].map((_, index) => (
                        <div key={index} className="waveform-bar" style={{ height: `${Math.random() * 100}%` }} />
                      ))}
                    </div>
                    <div className="audio-info">
                      <span className="audio-icon">🎵</span>
                      <span className="audio-filename">{attachments[0].name}</span>
                    </div>
                    <button className="remove-attachment" onClick={() => setAttachments([])}>×</button>
                  </div>
                )
              )}
              <div className="input-content">
                <div className="attachment-button" onClick={toggleAttachments}>
                  <img src={AppSvg.Paperclip} alt="attachments" style={{ width: 20, height: 20 }} />
                </div>
                <textarea
                  ref={inputRef}
                  className="chat-textarea"
                  value={input}
                  placeholder="Type a message..."
                  onChange={handleInputChange}
                  onKeyDown={onKeyDown}
                />
              </div>
            </div>
            {showAttachments && (
              <div className="attachment-options" ref={attachmentRef}>
                <div className="attachment-option" onClick={() => handleFileUpload('image')}>
                  <img src={AppSvg.imageSvg} alt="Upload" />
                  <span>Photos</span>
                </div>
                <div className="attachment-option" onClick={() => handleFileUpload('audio')}>
                  <img src={AppSvg.AudioSvg} alt="Record" />
                  <span>Recording</span>
                </div>
              </div>
            )}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={onFileSelected}
              onClick={(event) => {
                event.stopPropagation();
                console.log("File input onClick triggered");
              }}
            />
            {isGenerating ? (
              <button className="chat-send" onClick={onStopStreaming}>
                <div style={{ width: 17, height: 17, borderRadius: 3, background: 'white' }} />
              </button>
            ) : (
              <button
                className="chat-send"
                onClick={onSendMessage}
                disabled={isDisabled()}
                style={{
                  backgroundColor: isDisabled() ? '#FF8A50' : '#FF4A00',
                  cursor: isDisabled() ? 'not-allowed' : 'pointer',
                }}
              >
                <img src={AppSvg.send} alt="send" style={{ width: 25, height: 25 }} />
              </button>
            )}
          </div>
        </div>
      </div>
      {limitExceedModal && (
        <ConversionLimitModal
          user={user}
          onClose={() => setLimitExceedModal(false)}
          handlePremium={handlePremium}
        />
      )}
      {showFileSizeWarning && (
        <FileSizeWarningModal
          isOpen={showFileSizeWarning}
          onClose={() => setShowFileSizeWarning(false)}
        />
      )}
     
      
      {showSubscriptionModal && !user?.isPro && (
        <SubscriptionModal
          onClose={() => setShowSubscriptionModal(false)}
        />
      )}
      
      

    </>

  );
};



export default ChatScreen;
