import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useIsLoggedIn } from './redux/useStore';
import { LoginScreen } from './pages/auth';
import NotFoundScreen from './pages/global/notFound';
import ChatScreen from './pages/app/chat';
import { DrawerPageLayout } from './components';
import SettingsScreen from './pages/app/settings';
import { ApiCollection } from './config/envConfig';
import { useUser } from './redux/useStore';
import { useDispatch } from 'react-redux';
import { setActiveUser } from './redux/userSlice';
import { envConfig } from './config/envConfig';
import ErrorSimulator from './pages/global/error';
import SubscriptionModal from './components/PremiumSubscriptionModal';
import { useAxios } from './hooks/useAxios';
// import LearnScreen from './pages/app/learn/onboarding';
// import { CourseList, CourseDetail, CourseDetailQuestionare, CreateMyCourse, MyCourse } from './pages/app/learn/course';
// import CourseCustomizeScreen from './pages/app/learn/course/customize';
import { LocalUser } from './utils/interface/localUser';
// import CourseModule from './pages/app/learn/course/module';
// import UserPersona from './pages/app/settings/userPersona';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SignupScreen from './pages/auth/signUp';
import ForgotPasswordScreen from './pages/auth/forget';
import { googleClientId } from './config/envConfig';

const drawerWrapped = (component: any) => {
  return (
    <DrawerPageLayout>
      {component}
    </DrawerPageLayout>
  )
}

//created a new new compo to handle the authenticated routes
function AuthenticatedApp({ showSubscriptionModal, handleCloseModal }: {
  showSubscriptionModal: boolean;
  handleCloseModal: () => void;
}) {
  const user = useUser();

  return (
    <>
      <Routes>
        {/* CHAT SCREENS */}
        <Route path="/" element={drawerWrapped(<ChatScreen />)} />
        <Route path="/chat/:id" element={drawerWrapped(<ChatScreen />)} />
        <Route path="/settings">
          <Route index element={drawerWrapped(<SettingsScreen />)} />
          <Route path="profile" element={drawerWrapped(<SettingsScreen />)} />
          <Route path="data" element={drawerWrapped(<SettingsScreen />)} />
          <Route path="persona" element={drawerWrapped(<SettingsScreen />)} />
        </Route>

        {/* LEARN WITH CHANAKYA SCREENS */}

{/*   
     <Route path="/learn">
          <Route index element={<LearnScreen />} />
          <Route path="onboarding" element={<LearnScreen />} />
          <Route path="settings" element={<UserPersona />} />

         
          <Route path="course">
            <Route index element={<CourseList />} />
            <Route path="list" element={<CourseList />} />
            <Route path="detail/:id" element={<CourseDetail />} />
            <Route path="create" element={<CreateMyCourse />} />
            <Route path="detail/questionare" element={<CourseDetailQuestionare />} />
            <Route path="customize/:id" element={<CourseCustomizeScreen />} />
            <Route path="module" element={<CourseModule />} />
            <Route path="mycourse" element={<MyCourse />} />
          </Route>
        </Route> */}
        
        
         

        <Route path="*" element={<NotFoundScreen />} />

        {/* API Error Simulator */}
        {envConfig.isProd === false && <Route path="/errorSimulator" element={<ErrorSimulator />} />}
      </Routes>
      {showSubscriptionModal && user && !user.isPro && (
        <SubscriptionModal onClose={handleCloseModal} />
      )}

    </>
  );
}

function App() {
  const isLoggedIn = useIsLoggedIn();
  const user = useUser();
  const axios = useAxios();
  const dispatch = useDispatch();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const fetchUserPremiumData = async () => {
    try {
      const response = await axios.get(ApiCollection.user.subscriptionDetail);
      dispatch(setActiveUser({
        ...user,
        isPro: response.data.is_subscription_active,
        isProSelected: response.data.is_subscription_active
      } as LocalUser));
    } catch (error) {
      console.error('Error fetching user premium data:', error);
    }
  };

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if (isLoggedIn) {
        await fetchUserPremiumData();

        const modalShownKey = `subscription_modal_shown_${user?.id}`;
        const hasModalBeenShown = localStorage.getItem(modalShownKey);

        if (!user?.isPro && !hasModalBeenShown) {
          setShowSubscriptionModal(true);
          localStorage.setItem(modalShownKey, 'true');
        }
      }
    };

    checkSubscriptionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const handleCloseModal = () => {
    setShowSubscriptionModal(false);
  };

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <BrowserRouter>
        {!isLoggedIn ? (
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/signup" element={<SignupScreen />} />
            <Route path="/forget" element={<ForgotPasswordScreen />} />
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        ) : (
          <AuthenticatedApp
            showSubscriptionModal={showSubscriptionModal}
            handleCloseModal={handleCloseModal}
          />
        )}
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}



export default App;
