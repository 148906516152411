import React, { useState } from "react";
import './index.css';
import { AppSvg } from "../../../assets/svg";
import { Button } from "../../../components";
import axios from "axios";
import { ApiCollection } from "../../../config/envConfig";
import errorHandler from "../../../utils/common/errHandler";
import LoadingModal from "../../../components/loading-modal";
import { useNavigate } from "react-router-dom";


export default function SignupScreen() {
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const PASSWORD_REGEX = /^.{8,}$/;

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const validateFields = () => {
    let isValid = true;
    
    if (!name.trim()) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!EMAIL_REGEX.test(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (!PASSWORD_REGEX.test(password)) {
      setPasswordError("Password must contain at least 8 characters");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (!confirmPassword) {
      setConfirmPasswordError("Please confirm your password");
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }

    return isValid;
  };

  const onPressSignup = async () => {
    if (!validateFields()) {
      return;
    }
    
    setShowModal(true);
    try {
      const response = await axios.post(`${ApiCollection.user.signup}`, {
        email,
        password,
        name: name.trim()
      });
      
      if (response.data) {
        console.log(response.data)
        alert('Signup successful! Please login.');
        navigate('/login');
      } else {
        alert('Signup failed. Please try again.');
      }
    } catch (err) {
      errorHandler('Signup', err);
    } finally {
      setShowModal(false);
    }
  };


  return (
    <div className="login-screen">
      {showModal && <LoadingModal modalVisible={showModal} />}

      <div className="wrapper">
        <img src={AppSvg.chanakyaLogo} className="login-logo" alt="logo" />
        
        <div className="input-container">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError("");
            }}
            className="login-input"
          />
          {nameError && <span className="error-message">{nameError}</span>}
        </div>

        <div className="input-container">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            className="login-input"
          />
          {emailError && <span className="error-message">{emailError}</span>}
        </div>

        <div className="input-container">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError("");
            }}
            className="login-input"
          />
          {passwordError && <span className="error-message">{passwordError}</span>}
        </div>

        <div className="input-container">
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setConfirmPasswordError("");
            }}
            className="login-input"
          />
          {confirmPasswordError && <span className="error-message">{confirmPasswordError}</span>}
        </div>

        <Button
          title="Sign Up"
          onClick={onPressSignup}
          className="login-button"
        />


       
        </div>

        <div className="do-login-text">
          Already have an account? <span className="signup-link" onClick={() => navigate('/login')}>Login</span>
        </div>
      </div>
    
  );
}
