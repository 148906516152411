import axios from "axios";
import { ApiHeadNormal } from "../config/envConfig";
import { useUser } from "../redux/useStore";
import { useDispatch } from "react-redux";
import { setLogoutUser, setActiveUser } from "../redux/userSlice";

export const useAxios = () => {
  const user = useUser();
  const dispatch = useDispatch();

  const axiosConfig = {
    baseURL: ApiHeadNormal,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.token}`,
      version: "v0",
    },
  };

  const axiosInstance = axios.create(axiosConfig);

  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        // Removing the current interceptor kyunki infinite loops
        // important jab mutiple request failt hotiu hai toh
        axiosInstance.interceptors.response.eject(interceptor);

        const newToken = await refreshToken();
        if (newToken) {
          // Updating the failed request's authorization header with new token
          error.config.headers.Authorization = `Bearer ${newToken}`;

          // Using axios directly instead of axiosInstance to avoid interceptor
          return axios(error.config);
        }
      }
      return Promise.reject(error);
    }
  );

  const refreshToken = async () => {
    try {
      const response = await axios.post(
        `${ApiHeadNormal}/user/refresh-token/`,
        {
          token: user?.token,
        }
      );

      const updatedUser = {
        ...user,
        token: response.data.accessToken,
        id: user?.id || "",
        email: user?.email || "",
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        isActive: user?.isActive || false,
        profilePhoto: user?.profilePhoto || "",
        mobileNo: user?.mobileNo || "",
        profileBio: user?.profileBio || "",
        taskInterests: user?.taskInterests || "",
        preferredLanguage: user?.preferredLanguage || "",
        dateOfBirth: user?.dateOfBirth || "",
        isPro: user?.isPro || false,
        isProSelected: user?.isProSelected || false,
        skills: user?.skills || [],
        interests: user?.interests || [],
      };

      dispatch(setActiveUser(updatedUser));
      return response.data.accessToken;
    } catch (error) {
      dispatch(setLogoutUser());
      window.location.href = "/login";
      return null;
    }
  };

  return axiosInstance;
};
