import React from "react";
import { Drawer } from "..";
import './styles.css';


export default function DrawerPageLayout(props: any) {

    const [drawerMode, setDrawerMode] = React.useState<'closed' | 'opened'>('closed')

    const onMenuClick = () => {
        if (drawerMode === 'closed') {
            setDrawerMode('opened')
        } else {
            setDrawerMode('closed')
        }
    }

    return (
        <div className="drawer-screen">
       
            {drawerMode === 'closed' && (
                <div className="hamburger-menu" onClick={onMenuClick}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
            
            <Drawer
                onMenuClick={onMenuClick}
                mode={drawerMode}
                className={`drawer ${drawerMode}`}
            />
            <div className="drawer-screen-content" style={{ width: drawerMode === 'closed' ? '' : '80%' }}>
                {props.children}
            </div>
        </div>

    )
}