import React, { useState } from "react";
import './index.css';
import { AppSvg } from "../../../assets/svg";
import { Button } from "../../../components";
import axios from "axios";
import { ApiCollection } from "../../../config/envConfig";
import errorHandler from "../../../utils/common/errHandler";
import LoadingModal from "../../../components/loading-modal";
import { useNavigate } from "react-router-dom";


export default function ForgotPasswordScreen() {
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateFields = () => {
    let isValid = true;
    
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!EMAIL_REGEX.test(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    return isValid;
  };

  const onPressForgotPassword = async () => {
    if (!validateFields()) {
      return;
    }
    
    setShowModal(true);
    try {
      const response = await axios.post(`${ApiCollection.user.forget}`, {
        email
      });

      console.log(email)
      
      if (response.data) {
        alert('Password reset link has been sent to your email.');
        console.log(response.data)
        navigate('/login');
      } else {
        alert('Failed to send reset link. Please try again.');
      }
    } catch (err) {
      errorHandler('Forgot Password', err);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div className="login-screen">
      {showModal && <LoadingModal modalVisible={showModal} />}

      <div className="wrapper">
        <img src={AppSvg.chanakyaLogo} className="login-logo" alt="logo" />
        
        <h2 className="forgot-title">Forgot Password</h2>
        <p className="forgot-description">
          Enter your email address and we'll send you a link to reset your password.
        </p>

        <div className="input-container">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            className="login-input"
          />
          {emailError && <span className="error-message">{emailError}</span>}
        </div>

        <Button
          title="Send Reset Link"
          onClick={onPressForgotPassword}
          className="login-button"
        />

        <div className="remember-pass-text">
          Remember your password? <span className="signup-link" onClick={() => navigate('/login')}>Login</span>
        </div>
      </div>
    </div>
  );
}
